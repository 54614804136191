import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BePartnerService {
  constructor(private http: HttpClient) {}

  oldBePartner(data: any) {
    const url = `${environment.BackEndURL}/api/Public/MerchantsOnboarding/CreateLead`;

    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return this.http.post(url, formData);
  }

  bePartner(data: any) {
    const url = `${environment.BackEndURL}/Mail/send`;

    return this.http.post(url, data);
  }
}
