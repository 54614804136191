import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationLanguages } from 'src/app/enums/lang';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {
  ApplicationLanguages = ApplicationLanguages;
  currentLang = localStorage.getItem('lang') || ApplicationLanguages.Ar;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}
}
