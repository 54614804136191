import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { IslamicCommitteeComponent } from './pages/islamic-committee/islamic-committee.component';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { EmailConfirmationOrExpiredComponent } from './pages/email-confirmation-or-expired/email-confirmation-or-expired.component';
import { ErrorPageNotFoundComponent } from './pages/error-page-not-found/error-page-not-found.component';

const routes: Routes = [
  {
    path: ':lang/home',
    component: HomeComponent,
  },
  {
    path: 'home',
    redirectTo: '/ar/home',
  },
  {
    path: ':lang/about-us',
    component: AboutUsComponent,
  },
  {
    path: 'about-us',
    redirectTo: '/ar/about-us',
  },
  {
    path: ':lang/be-partner',
    component: PartnerComponent,
  },
  {
    path: 'be-partner',
    redirectTo: '/ar/be-partner',
  },
  {
    path: ':lang/blogs',
    component: BlogsComponent,
  },
  {
    path: 'blogs',
    redirectTo: '/ar/blogs',
  },
  {
    path: ':lang/faq',
    component: FaqComponent,
  },
  {
    path: 'faq',
    redirectTo: '/ar/faq',
  },
  {
    path: ':lang/terms-and-conditions',
    component: TermsAndConditionsComponent,
  },
  {
    path: 'terms-and-conditions',
    redirectTo: '/ar/terms-and-conditions',
  },
  {
    path: ':lang/privacy',
    component: PrivacyComponent,
  },
  {
    path: 'privacy',
    redirectTo: '/ar/privacy',
  },
  {
    path: ':lang/islamic-committee',
    component: IslamicCommitteeComponent,
  },
  {
    path: 'islamic-committee',
    redirectTo: '/ar/islamic-committee',
  },
  {
    path: ':lang/complaints',
    component: ComplaintsComponent,
  },
  {
    path: 'complaints',
    redirectTo: '/ar/complaints',
  },
  {
    path: ':lang/email-confirmation',
    component: EmailConfirmationOrExpiredComponent,
  },
  {
    path: 'email-confirmation',
    redirectTo: '/ar/email-confirmation',
  },
  {
    path: ':lang/not-found',
    component: ErrorPageNotFoundComponent,
  },
  {
    path: 'not-found',
    redirectTo: '/ar/not-found',
  },
  {
    path: '',
    redirectTo: '/ar/home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/ar/not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
