<div class="page-not-found">
  <div class="container">
    <div class="data-container">
      <div class="data">
        <img
          class="logo"
          loading="lazy"
          src="assets/images/madfu-logo.svg"
          alt="Madfu"
        />
        <h1 class="title">{{ "error-page-not-found-title" | translate }}</h1>
        <p class="subtitle">
          {{ "error-page-not-found-subtitle" | translate }}
        </p>
      </div>

      <img
        class="not-found-logo"
        loading="lazy"
        src="assets/images/not-found.png"
        alt="Madfu"
      />
    </div>
  </div>
</div>
