import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category, partner } from 'src/app/interfaces/category';
import { ApplicationLanguages } from 'src/app/enums/lang';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private dataUrlAr = 'assets/data/categories.json';
  private dataUrlEn = 'assets/data/categories-en.json';
  private storePartners = 'assets/data/store-partners.json';
  private onlinePartners = 'assets/data/online-partners.json';
  private growthDataTogether = 'assets/data/growth-together-data.json';
  private shariaCommittee = 'assets/data/sharia-committee-data.json';

  constructor(private http: HttpClient) {}

  getCategories(): Observable<any> {
    return this.http.get<Category[]>(
      localStorage.getItem('lang') == ApplicationLanguages.Ar
        ? this.dataUrlEn
        : this.dataUrlAr
    );
  }

  getStorePartners(): Observable<any> {
    return this.http.get<partner[]>(this.storePartners);
  }

  getOnlinePartners(): Observable<any> {
    return this.http.get<partner[]>(this.onlinePartners);
  }

  getGrowthData(): Observable<any> {
    return this.http.get(this.growthDataTogether);
  }

  getShariaData(): Observable<any> {
    return this.http.get(this.shariaCommittee);
  }
}
