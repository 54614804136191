<div class="cover-widget">
  <div class="container">
    <h1>{{ "TERMS_CONDITIONS" | translate }}</h1>
  </div>
</div>

<div class="terms-data" *ngIf="currentLang == ApplicationLanguages.Ar">
  <div class="container">
    <p class="heading center first-item">
    <p><span dir="RTL">اتفاقية "</span><strong><span dir="RTL">مدفوع</span></strong><span
      dir="RTL">" (الشروط والأحكام)</span></p>
    <strong>
      <p class="heading center">تاريخ السريان 29/12/2024م</p>
    </strong>
    <p></p>
    <p>
      <strong><label class="heading">التمهيد:</label></strong>
    </p>
    <p dir="RTL">شركة مدفوع العربية للدفع الآجل، شركة مساهمة غير مدرجة، سجل تجاري رقم (١٠١٠٧٤٧٠٧٧) المسجل في مدينة
      الرياض، بالمملكة العربية السعودية، ويشار إليها فيما بعد بـ("<strong>نحن أو الشركة أو مدفوع أو المنصة</strong>")،
      هي شركة مصرح لها من البنك المركزي لممارسة نشاط الدفع الآجل (<strong><span dir="LTR">BNPL</span></strong>)
      ("<strong>الخدمات</strong>")، وذلك من خلال موقع مدفوع الإلكتروني أو تطبيق مدفوع أو المتاجر الإلكترونية أو الفعلية
      التي تتيح الدفع عن طريق مدفوع ("<strong>قنوات مدفوع</strong>")؛ وينحصر دور مدفوع في تسهيل عملية دفع قيمة العمليات
      الشرائية التي تتم بين الأشخاص والمتاجر، ولا تمتد مسؤولية مدفوع إلى أي من مسؤوليات هؤلاء الأشخاص أو المتاجر
      المعتادة تجاه بعضهما البعض.&nbsp;</p>
    <p dir="RTL">منصة مدفوع ليست متجر، وهي غير مسؤولة عن دقة المعلومات المعروضة -فيها أو خارجها- من المتاجر أو نظامية
      المنتجات أو الخدمات المقدمة من المتاجر المشاركين. كما أن منصة مدفوع ليست جهة تحقق من معلومات أو هويات الأشخاص
      المستفيدين من الخدمة. وفي جميع الأحوال تتحمل المتاجر ويتحمل الأشخاص كامل المسؤوليات المتعلقة بعمليات الشراء التي
      تجري على المنصة فيما بينهما.</p>
    <p dir="RTL">وحيث إنك ترغب في الاستفادة من الخدمات المقدمة من مدفوع، فإنك تُقر بمجرد وصولك أو استخدامك لأي من خدمات
      مدفوع أو تسجيل حساب مستخدم بأنك اطلعت على هذه الشروط والأحكام وفهمتها ووافقت على الالتزامات الواردة فيها، كما تقر
      بعلمك أن هذه الشروط والأحكام عقد إلزامي بينك وبين مدفوع؛ وقد تم إبرامه بين الطرفين بكامل أهليتهما الشرعية
      والنظامية على الآتي:</p>
    <p dir="RTL"><strong><u>&nbsp;(1): أهمية التمهيد:&nbsp;</u></strong></p>
    <p dir="RTL">يعتبر التمهيد أعلاه جزءً لا يتجزأ من الاتفاقية ومكملاً لها وتفسر بنودها وفقاً له.</p>
    <p dir="RTL"><strong><u>&nbsp;(2): أطراف الاتفاقية:</u></strong></p>
    <p dir="RTL"><span dir="LTR">2/1 تعتبر الاتفاقية عقد مبرم بين مدفوع وبين كل من يستفيد أو يصل لخدمات مدفوع ("<strong>المستفيد</strong>") عبر أي من قنوات مدفوع.</span>
    </p>
    <p dir="RTL"><span dir="LTR">2/2</span>&nbsp;لا يعتبر التاجر الذي يشتري منه المستفيد سلع أو خدمات باستخدام خدمات
      مدفوع طرفاً في الاتفاقية، وتعد أي شروط سارية بين التاجر والمستفيد أو التاجر ومن يتعاقد معه لتنفيذ التزاماته أمام
      المستفيد أو التاجر ومدفوع مستقلة عن الاتفاقية.</p>
    <p dir="RTL"><strong><u>&nbsp;(3): التعريفات:&nbsp;</u></strong></p>
    <p dir="RTL">لأغراض تطبيق الاتفاقية، يقصد بالكلمات والعبارات الآتية &ndash;أينما وردت في الاتفاقية&ndash; المعاني
      المبينة أمام كل منها، ما لم يقتضِ السياق غير ذلك:</p>
    <p dir="RTL"><strong>3/1 الاتفاقية</strong>: هذا العقد المبرم بين مدفوع والمستفيد بما في ذلك الشروط والأحكام الواردة
      فيه وسياسة الخصوصية الخاصة بمدفوع وأي تحديثات تطرأ عليهما من وقت إلى آخر، وأي شروط وأحكام أخرى يشار إلى أنها جزء
      من الاتفاقية.</p>
    <p dir="RTL"><strong>3/2 الشركة أو مدفوع</strong>: شركة مدفوع العربية للدفع الآجل، سجل تجاري رقم (١٠١٠٧٤٧٠٧٧) المسجل
      في مدينة الرياض، بالمملكة العربية السعودية ("<strong>مدفوع</strong>")، وقد يشار إليها بالضمير
      ("<strong>نحن</strong>") أو نون الجماعة أو أي ضمائر أخرى مماثلة في الاتفاقية.</p>
    <p dir="RTL"><strong>3/3 المستفيد</strong>: الشخص ذو الصفة الطبيعية الذي يستخدم أي من خدمات مدفوع لإتمام عملية شراء
      مع أي من المتاجر المشاركة، وقد يشار إليه بضمير المخاطب ("<strong>أنت</strong>") أو (<strong>"ك</strong>") أو أي
      ضمائر أخرى مماثلة في الاتفاقية.</p>
    <p dir="RTL"><strong>3/4 التاجر أو المتاجر المشاركة</strong>: المتجر -الإلكتروني أو التطبيقات أو المتاجر الفعلية-
      التي توفر خدمات السداد عبر مدفوع، أو المتاجر المعروضة في منصة مدفوع، والتي يعرض فيها التاجر السلع والخدمات
      والمنتجات الخاصة به وغيرها، وفقاً للاتفاقية بينه وبين مدفوع.&nbsp;</p>
    <p dir="RTL"><strong>3/5 قنوات مدفوع</strong>: الوسيلة التي يقدمها التاجر للمستفيد لتمكين المستفيد من اختيار مدفوع
      كوسيلة دفع ويشمل ذلك على سبيل المثال لا الحصر الباركود الموضح في الفاتورة الذي ينقل المستفيد لصفحة الدفع الخاصة في
      مدفوع.</p>
    <p dir="RTL"><strong>3/6 الموقع الإلكتروني</strong>: الموقع الإلكتروني الخاص بشركة مدفوع WWW.MADFU.COM.SA</p>
    <p dir="RTL"><strong>3/7 التطبيق</strong>: تطبيق مدفوع المتوفر على (Apple App Store) أو (Google Play Store) أو أي
      نظام تشغيل آخر يتوفر فيه التطبيق الخاص بمنصة مدفوع.</p>
    <p dir="RTL"><strong>3/8 حساب مدفوع</strong>: الحساب الذي أنشأه المستفيد ليتمكن من استخدام خدمات مدفوع حسب الشروط
      والأحكام المنصوص عليها في الاتفاقية.</p>
    <p dir="RTL"><strong>3/9 محفظة مدفوع</strong>: محفظة مالية إلكترونية مرتبطة بحسابك في مدفوع، يتم إضافة الاسترداد
      النقدي و قيمة المسترجعات بها.</p>
    <p dir="RTL"><strong>3/10 خدمات الدفع من مدفوع</strong>: تسهيل إجراءات الدفع الآجل للأشخاص عبر الدفع بالتقسيط.</p>
    <p dir="RTL"><strong><u>(4): إقرارات المستفيد:</u></strong></p>
    <p dir="RTL">4/1 يقر المستفيد أنه بكامل الأهلية المعتبرة شرعاً ونظاماً لإبرام الاتفاقية وفق الشروط والأحكام المنصوص
      عليها، وأنه أهل للتصرفات القانونية اللازمة لفتح حساب أو استخدام خدمات مدفوع.</p>
    <p dir="RTL">4/2 يقر المستفيد بعلمه أن الوصول إلى منصة مدفوع أو استخدام أي من خدمات مدفوع أو النقر بالموافقة على
      الاتفاقية أو الشروط والأحكام إقراراً منه على إبرام الاتفاقية إلكترونياً بينه وبين مدفوع حسب أنظمة المملكة العربية
      السعودية.</p>
    <p dir="RTL">4/3 يقر المستفيد أن مدفوع تملك كامل الصلاحية في تحديث الاتفاقية وتقع عليه مسؤولية مراجعة المعلومات
      المنشورة على تطبيقنا أو موقعنا الإلكتروني بانتظام ويستعرض الاتفاقية بانتظام قبل استخدامه لأي من خدمات مدفوع.
      واستمرار استخدامه للخدمات على الشروط يعبر عن موافقته على الشروط والتعديلات المستحدثة عليها.</p>
    <p dir="RTL">4/4 يقر المستفيد أنه يفوض مدفوع في استخدام أي من أو جميع معلومات التعريف والبيانات الشخصية الخاصة به
      التي تم جمعها من خلال المواقع الإلكترونية أو المنصات أو تطبيقات الهواتف المحمولة الخاصة بمدفوع أو التجار من وقتٍ
      لآخر ويجوز استخدامها أو الإفصاح عنها إلى أشخاص آخرين وفقًا لسياسة الخصوصية الخاصة بمدفوع. وقد يشمل ذلك مشاركة
      معلومات التعريف الخاصة به وأية معلومات أخرى مع أطراف خارجية لأغراض التحقق من الائتمان ومكافحة غسل الأموال أو غيرها
      من الفحوصات المماثلة أو لأي غرض آخر تعتبره مدفوع ضروري من أجل تمكينها من تقديم خدمات الدفع للمستفيد والالتزام
      بجميع الأنظمة واللوائح المعمول بها.</p>
    <p dir="RTL">4/5 يقر المستفيد أنه مقيم في المملكة العربية السعودية ولديه عنوان إقامة دائم فيها.</p>
    <p dir="RTL">4/6 يقر المستفيد ويوافق على تحديث معلومات حسابه لدينا ودقتها. ويقر بأن أي عنوان سكني أو عنوان بريد
      إلكتروني أو رقم هاتف جوال يقدمه لنا هو عنوان صحيح يخصّه وقت تقديمه. ويتحمل المستفيد مسؤولية إخطار مدفوع بشكل فوري
      عند تغيير أي من المعلومات الواردة أعلاه وكما يحق لشركة مدفوع طلب تحديث بيانات المستخدم أو طلب بيانات إضافية سواءً
      من المستخدم أو أية أطراف أخرى على سبيل المثال (سمة).</p>
    <p dir="RTL">4/7 يتعهد المستفيد أنه سيقوم بسداد جميع التزاماته المالية الناجمة عن عمليات الشراء الخاصة به، وفقاً
      لشروط الدفع المتفق عليها وقت الشراء.</p>
    <p dir="RTL">4/8 يقر المستفيد أن الشركة تمتلك الحق في أي وقت، دون الحاجة لإخطاره، ببيع، أو إسناد، أو نقل ملكية
      حسابه، أو أي مبالغ مستحقة على حسابه، أو عقده معنا، أو أي من حقوقنا أو التزاماتنا تجاهه بموجب حسابه أو بموجب هذه
      الشروط إلى أي شخص أو كيان آخر، وفي هذه الحالة تنتقل كافة الحقوق والالتزامات المتعلقة بالحساب بموجب هذه الشروط إلى
      هذا الشخص أو الكيان.</p>
    <p dir="RTL">4/9 يقر المستفيد ويتعهد بعدم استخدام خدمة مدفوع لأي أغراض محرمة أو غير قانونية كشراء منتجات أو خدمات
      ممنوعة بموجب الأنظمة السارية في المملكة العربية السعودية.</p>
    <p dir="RTL">4/10 يقر المستفيد بأنه مسؤول عن جميع الخسائر التي قد تتكبدها مدفوع نتيجة لاستخدام حسابه في مدفوع من قبل
      أي شخص أخر حصل عليه بموافقته.&nbsp;</p>
    <p dir="RTL">4/11 يقر المستفيد بأن مدفوع لن تكون مسؤولة عن رفض أي متجر لقبول مدفوع كخيار دفع/ كما أنها لن تكون
      مسؤولة بأي طريقة عن البضائع والخدمات المقدمة من المتاجر، وللمستفيد حل أي من تلك الشكاوي مباشرة مع المتجر، ولن
      يترتب على مدفوع أي مسؤولية في هذا الخصوص ولن تكون أي دعوى من المستفيد ضد المتجر موضوع مطالبه تجاه مدفوع، ولن تقوم
      مدفوع برد أي مبلغ استرداد إلا بعد أن تتلقى من المتجر طلب إرجاع صادر صحيح بشأن العملية المُسترجعة سواء كليًا أو
      جزئيًا.&nbsp;</p>
    <p dir="RTL"><strong><u>(5) التسجيل وإنشاء حساب:</u></strong></p>
    <p dir="RTL">5/1 حتى يتسنى لك الاستفادة من خدمات مدفوع، عليك أولاً استكمال بيانات التسجيل الخاصة بمدفوع وفتح حساب
      مدفوع حسب المتطلبات الواردة في صفحة التسجيل.</p>
    <p dir="RTL">5/2 يلتزم المستفيد بتقديم معلوماته إلى مدفوع حسب متطلبات البنك المركزي السعودي (اعرف عميلك) والتي
      تتضمن:</p>
    <ul>
      <li>الاسم الرباعي.</li>
      <li>رقم الهوية الوطنية أو رقم الإقامة بالمملكة العربية السعودية.</li>
      <li>العمر.</li>
      <li>العنوان الوطني.</li>
      <li>وسائل التواصل: البريد الإلكتروني، ورقم الهاتف.&nbsp;</li>
      <li>أي معلومات أخرى تقررها مدفوع من وقت لآخر أو تقررها الأنظمة المرعية.&nbsp;</li>
    </ul>
    <p dir="RTL"><span dir="LTR">5/3 يقر المستفيد على صحة المعلومات المقدمة منه ويتحمل مسؤولية أي خطأ في المعلومات، ويقر بموافقته على حفظ المعلومات واستخدامها وفقاً لهذه الأحكام وأحكام سياسة الخصوصية الخاصة بمدفوع.&nbsp;</span>
    </p>
    <p dir="RTL"><span dir="LTR">5/4 يفوض مدفوع بأن تحصل على ما يلزم أو تحتاج إليه من معلومات عنه، من الشركة السعودية للمعلومات الائتمانية (سمة) أو غيرها. كما ويوافق على أن تفصح مدفوع عن المعلومات الائتمانية الخاصة به للشركة السعودية للمعلومات الائتمانية (سمة) أو غيرها، من خلال اتفاقية العضوية المبرمة وقواعد العمل المقرة والخاصة بتبادل المعلومات ولأي جهة أخرى يوافق عليها البنك المركزي السعودي (ساما).</span>
    </p>
    <p dir="RTL"><span dir="LTR">5/5 يقر المستفيد بعلمه أن عدم التزامه بسداد الالتزامات المستحقة سيؤثر على سجله الائتماني لدى الشركة السعودية للمعلومات الائتمانية (سمة) أو غيرها.</span>
    </p>
    <p dir="RTL">5/6 يتحمل المستفيد كامل المسؤولية عن أي نشاط يجري في حسابه لدى مدفوع، ولا يجوز له نقل الحساب أو السماح
      للغير باستخدامه، ويجب على المستفيد الحفاظ على كلمة المرور الخاصة بحساب مدفوع، وإخطار مدفوع فوراً في حال فقدان أو
      سرقة كلمة المرور الخاصة به، أو إذا كان يعتقد أنه تم الوصول إلى حسابه من قبل الغير، كما يتحمل المستفيد مسؤولية أي
      معاملات تتم باستخدام حسابه المسروق ما لم يبادر على الفور بإخطار مدفوع بإيقاف الحساب، وتلقيه رسالة من مدفوع تفيد
      بتأكيد إيقاف الحساب.</p>
    <p dir="RTL">5/7 يحق للشركة إيقاف الخدمة عن المستفيد وإيقاف عملية دخوله على الموقع الإلكتروني، أو التطبيق، أو
      خدماته، أو غيرها، أو تحديد الدخول، أو قصره، في حال ثمة شكوك لدى مدفوع بأن أية معلومة من المعلومات التي قدمها
      المستفيد هي معلومة غير صحيحة، أو غير دقيقة، أو غير كاملة، أو غير محدثة، أو في حال الاشتباه بغسل الأموال أو أي
      عمليات مشبوهة ويحق لمدفوع إجراء عمليات التحقيق والتحري عند الاشتباه بالاحتيال أو إساءة الاستعمال لأنظمة مدفوع
      ويشمل ذلك التعامل مع جهة ثالثة لهذا الغرض. دون إخلال بأي حقوق أخرى وتعويضات مكفولة لمدفوع وفق الشروط والأحكام هذه
      أو بمقتضى الأنظمة المرعية.</p>
    <p dir="RTL"><strong><u>(6): الخدمات المقدمة:</u></strong></p>
    <p dir="RTL">6/1 تتيح لك خدمة الدفع الآجل من مدفوع دفع التكلفة الاجمالية للمنتج أو الخدمة "المبلغ الإجمالي" من خلال
      تقسيم المبلغ إلى عدة أقساط بمبالغ محددة متفق عليها "الأقساط" مقسمة على فترات، ويتم دفعها في تواريخ محددة متفق
      عليها "تواريخ الاستحقاق"، عند إتمامك لطلب الدفع من خلال مدفوع.</p>
    <p dir="RTL">6/2 تراجع مدفوع معلوماتك وتتحقق منها من خلال مصدر موثوق مستقل قبل الموافقة على طلب الاستفادة من خدمة
      الدفع الآجل.</p>
    <p dir="RTL">6/3 لمدفوع الحق في رفض طلبك للاستفادة من خدمة الدفع الآجل في أي وقت ودون إبداء أي أسباب.</p>
    <p dir="RTL">6/4 تتيح مدفوع خدمة الدفع الآجل فقط للأشخاص المؤهلين، وتحدد الأهلية وفقاً لتقدير مدفوع عند محاولتك
      الشراء باستخدام الخدمة، وقد يعتمد كذلك على عامل أو عدة عوامل، مثل تقييمنا للجدارة الائتمانية وسجل معاملاتك، وكذلك
      سجل حساب التاجر، أو أي سبب آخر. وفي كافة الأحوال يجب أن تستوفي المعايير التالية لتعد شخصًا مؤهلًا:&nbsp;</p>
    <ol>
      <li>لديك الأهلية اللازمة والمعتبرة شرعاً ونظامًا للموافقة على هذه الشروط والاحكام.</li>
      <li>تبلغ من العمر 18 عام هجري أو أكثر.</li>
      <li>أن تكون مقيم فالمملكة العربية السعودية ولديك عنوان إقامة دائم.</li>
      <li>أن يكون لديك رقم هاتف محمول نشط مسجل باسمك.</li>
      <li>أن يكون لديك بريد إلكتروني نشط مسجل باسمك.</li>
      <li>أن تقدم جميع المعلومات والوثائق التي تطلبها مدفوع منك.</li>
    </ol>
    <p dir="RTL">6/5 تتيح لك خدمة الدفع الآجل من مدفوع السداد عن طريق وسائل الدفع المتاحة من أطراف ثالثة مثل: مدى، ماستر
      كارد، فيزا، وغيرها. ويمكن الاستفادة من هذه الخدمات عن طريق منصة مدفوع مباشرة &ndash;سواء عبر الموقع الإلكتروني أو
      التطبيق&ndash; وكذلك يمكن الاستفادة منها عن طريق المتاجر الإلكترونية أو الفعلية المشاركة.</p>
    <p dir="RTL">6/6 يحق لمدفوع أن تضع حد إجمالي لخدمة الدفع الآجل، وهذا الحد هو إجمالي المبلغ المتاح لك لعمليات الشراء
      عن طريق الدفع الآجل وفقاً لتقييمك الائتماني لدى مدفوع والشركة السعودية للمعلومات الائتمانية ("سمة"). ويحق لمدفوع
      تعديل الحد الإجمالي للدفع سواءً بالزيادة أو النقصان بناءً على تقديرها.</p>
    <p dir="RTL">6/7 يحق لشركة مدفوع سحب موافقتها على عملية شرائية معتمدة في أي وقت في حالة الاشتباه بالاحتيال أو في
      حالة النزاع أو لأي أسباب منصوص عليها في الاتفاقية، ويتم سحب موافقة مدفوع تلقائياً إذا تم تغيير أي من شروط المبيعات
      من جانب التاجر فيما يتعلق بالعملية الشرائية المعتمدة دون موافقة مدفوع، وذلك دون إخلال بأية حقوق أخرى وتعويضات
      مكفولة لمدفوع وفق الشروط والأحكام هذه أو بمقتضى الأنظمة المرعية.</p>
    <p dir="RTL"><strong><u>&nbsp;(7): المدفوعات</u></strong></p>
    <p dir="RTL">7/1 بموجب الاتفاقية فإن مدفوع ملزمة بإيضاح مقدار مبالغ الأقساط المتعين عليك سدادها وعددها، ومددها عن كل
      عملية شراء، كما أنها ملزمة بتذكيرك &ndash;على بيانات التواصل المقدمة منك&ndash; بتاريخ سداد كل قسط قبل حلوله.&nbsp;</p>
    <p dir="RTL">7/2 بموجب الاتفاقية فإنك تقر بعلمك والتزامك في سداد جميع الالتزامات المالية والأقساط الناجمة عن عمليات
      الشراء الخاصة بك، وفقاً لشروط الدفع المتفق عليها وقت الشراء.</p>
    <p dir="RTL">7/3 يقر المستفيد بأن لمدفوع الحق في طلب تسجيل أكثر من بطاقة أو مصدر تمويل واحد في حساب مدفوع لاستخدامها
      فيما يتعلق بسداد الأقساط المستحقة، وسيطلب منه تحديد خيار افتراضي بحيث يتم استقطاع المبالغ المستحقة المتفق عليها
      &ndash;بما في ذلك الدفعة الأولى المقدمة أو أي قسط مستحق&ndash; لكل عملية شرائية في تاريخ الاستحقاق المحدد في جدول
      الدفعات.&nbsp;</p>
    <p dir="RTL">7/4 يقر المستفيد بعلمه أن مدفوع -وفقًا لتقديرها- تملك الحق بتحديد أي طرق دفع مقبولة، ويجوز لها عدم قبول
      أي طريقة دفع معينة في أي وقت ولأي سبب.</p>
    <p dir="RTL">7/5 بموجب الاتفاقية فإنك تفوض مدفوع في التعامل مع جهات أخرى فيما يتعلق بخدمات السداد، بما في ذلك بطاقات
      الخصم أو الائتمان، وتلتزم مدفوع في عدم تخزين بطاقات الائتمان في نظامها.</p>
    <p dir="RTL">7/6 تقر بعلمك أن مدفوع ليست مسؤولة عن أي أضرار ناتجة عن أي أخطاء أو إشكاليات من الجهات الأخرى فيما
      يتعلق بدفع المبالغ المستحقة وغيرها. ولا تفرض مدفوع أي رسوم فيما يتعلق بالتعامل مع الجهات الأخرى (إلا إذا تم النص
      على ذلك).</p>
    <p dir="RTL">7/7 يقر المستفيد بالتزامه في أن تحتوي البطاقة أو مصدر التمويل المقدم منه على المبالغ اللازمة لسداد كامل
      الالتزامات المالية وأن تظل صالحة حتى سداد كافة المستحقات.</p>
    <p dir="RTL">7/8 في حال تعذر تحصيل الأقساط المستحقة من البطاقة الافتراضية، يقر المستفيد أنه يمنح مدفوع الصلاحية
      الكاملة في تحصيل الأقساط المستحقة من أي أو كافة البطاقات أو مصادر التمويل المسجلة في حساب مدفوع، وذلك بحسب تقدير
      مدفوع. &nbsp;</p>
    <p dir="RTL">7/9 في حال لم تتمكن مدفوع أو من ينوب عنها في سحب القسط المستحق وفقًا للفقرة السابقة، فإن المستفيد يقر
      بعلمه بوجوب سداد القسط المتأخر لمدفوع على الفور.&nbsp;</p>
    <p dir="RTL">7/10 يقر المستفيد بتنازله عن أي حقوق في إجراء عمليات رد المبالغ المدفوعة أو عمليات الإلغاء، وذلك في
      حالة استخدام طرق دفع قابلة للإلغاء كبطاقة الائتمان.</p>
    <p dir="RTL">7/11 يقر المستفيد بعلمه بالأضرار والعواقب المحتملة عند استخدام خدمة الدفع الآجل من مدفوع بمخالفة الشروط
      المتفق عليها، ويوافق على أحقية مدفوع في حالة تأخرك أو عجزك عن السداد في إخطار الشركة السعودية للمعلومات الائتمانية
      (سمة) أو غيرها، وأحقية مدفوع في منعه من استخدام حساب مدفوع أو الحد منه، مع بقائه في جميع الأحوال مسؤولاً مسؤولية
      كاملة عن المبالغ المستحق دفعها لمدفوع.</p>
    <p dir="RTL">7/12 يقر المستفيد أن مدفوع غير ملتزمة برد المدفوعات المختلف عليها مع التاجر. ومع ذلك، &nbsp;قد تساعد
      مدفوع -بالقدر المستطاع- في عمليات استرداد المدفوعات وإعادة المبالغ من المتاجر في بعض الحالات.</p>
    <p dir="RTL">7/13 يحق للمستفيد بموجب الاتفاقية أن يسدد إجمالي المبلغ المستحق قبل حلوله كليًا أو جزئيًا، دون أي رسوم
      إضافية للدفع المسبق .</p>
    <p dir="RTL">7/14 يحق لمدفوع التعامل مع أطراف ثالثة بغرض التعامل مع حالات تأخر المستفيد عن الدفع، ويشمل ذلك على سبيل
      المثال لا الحصر الأطراف الثالثة التي تعمل في أنشطة تحصيل أو شراء الديون.&nbsp;</p>
    <p dir="RTL">7/15 يقر المستفيد بعلمه أنه في حال عدم سداد المستحقات في تاريخ استحقاقها وفقًا لجدول الدفعات، فإنه
      يتحمل كافة التكاليف التي تتكبدها مدفوع لأغراض تحصيل مستحقاتها، ومن ذلك التكاليف القضائية، وأتعاب المحاماة، وأتعاب
      شركات تحصيل الديون وأي تكلفة أخرى تتكبدها مدفوع لأغراض تحصيل مستحقاتها.</p>
    <p dir="RTL">7/16 تلتزم مدفوع بإعفائك من المبالغ المطالب بها لصالحها في حالة الوفاة أو العجز الكلي وذلك خلال مدة
      أقصاها ثلاثين يوم من تاريخ استلام المستندات المثبتة لذلك وإعادة ما تم خصمه بالزيادة من تاريخ الوفاة أو العجز
      الكلي، ويستثنى من ذلك حالات الوفاة أو العجز الكلي الناتجة عن أي من الآتي:&nbsp;</p>
    <p dir="RTL">7/16/1 تعمد المستفيد إصابة ذاته، او محاولته للانتحار &ndash;سواء كان عاقلاً او مختلًا عقلياً&ndash; في
      ذلك الوقت.</p>
    <p dir="RTL">7/16/2 الكوارث الطبيعية والحروب.</p>
    <p dir="RTL">7/16/3 قرارات المحكمة أو السلطة القضائية المختصة بموجب الأنظمة السارية في المملكة العربية السعودية.</p>
    <p dir="RTL">7/16/4 تعاطي الكحول أو المخدرات أو المواد الممنوعة.</p>
    <p dir="RTL">7/16/5 الاشتراك أو التدريب على أي رياضة أو منافسة خطرة، كالاشتراك في سباقات الخيل أو سباقات
      السيارة.</p>
    <p dir="RTL">7/16/6 وفاة أو إصابة ناشئة عن طبيعة العمل.&nbsp;</p>
    <p dir="RTL"><strong><u>(8): العروض والمكافآت:</u></strong></p>
    <p dir="RTL">8/1 قد تقرر الشركة إجراء عروض ترويجية لبعض أو كل المتاجر، وذلك عن طريق تقديم مكافآت تحفيزية لإجراء
      المشتريات من بعض أو كل المتاجر أو لتنشيط المنصة في حال موافقة المتاجر المشتركة. وقد تكون تلك المكافآت على شكل
      استرداد جزء أو كامل مبلغ المشتريات عن طريق خدمات مدفوع، وذلك بإيداع مبالغ في محفظة مدفوع الخاصة
      بالمستفيد.&nbsp;</p>
    <p dir="RTL">8/2 يقر المستفيد بأن تلك المكافآت والمبالغ ليست قابلة للاسترداد النقدي، وخاضعة لفترة صلاحية محددة من
      قبل مدفوع.</p>
    <p dir="RTL">8/3 الشروط الخاصة في الاسترداد النقدي بمحفظة مدفوع الخاصة بك:</p>
    <p dir="RTL">8/3/1 يقر المستفيد بأن الحصول على الاسترداد النقدي مقتصر على الطلبات التي سددت بالكامل فقط، كما يقر بأن
      يستثنى من مبلغ الاسترداد قيمة ضريبة القيمة المضافة، ورسوم الخدمات ونقاط البيع.</p>
    <p dir="RTL">8/3/2 يقر المستفيد بأن مبلغ الاسترداد النقدي المودع في محفظته لدى مدفوع لا يمكن استخدامه للإيفاء
      بالتزاماته في مواجهة مدفوع، وينحصر استخدام مبلغ الاسترداد النقدي في عمليات الشراء الجديدة عن طريق خدمات مدفوع.</p>
    <p dir="RTL">8/3/3 يقر المستفيد بأن مدة صلاحية استخدام الاسترداد النقدي في محفظته لدى مدفوع هي ستة (6) أشهر، كما يحق
      لمدفوع وفقاً لتقديرها المطلق التعديل على مدة الصلاحية من حين لأخر على أن تخطر المستفيد قبل تفعيل التعديل بمدة لا
      يقل 15 يوم.</p>
    <p dir="RTL">8/3/4 لمدفوع وضع حد أعلى لقيمة الاسترداد النقدي وفقًا لتقديرها.</p>
    <p dir="RTL">8/3/5 تحتفظ مدفوع بالحق في عدم إضافة قيمة أي استرداد نقدي مكتسب أو حذف أي استرداد نقدي متراكم على
      محفظتك في مدفوع؛ حال قيامك بإساءة استخدام خدمات مدفوع.</p>
    <p dir="RTL">8/3/6 تحتفظ مدفوع بحقها في عدم تفعيل الاسترداد النقدي على العمليات الشرائية لفئة مختارة من التجار.</p>
    <p dir="RTL">8/3/7 الاسترداد النقدي له قيمة نقدية؛ ولا يمكن استردادها إلا في صورة رصيد يضاف إلى محفظتك.&nbsp;</p>
    <p dir="RTL">8/3/8 يجوز لنا تعديل الشروط المنصوص عليها في هذا البند (8/3) أو إلغاء اكتسابك للاسترداد النقدي في أي
      وقت بحسب أحكام تعديل الاتفاقية الواردة في المادة (12) من الاتفاقية.&nbsp;</p>
    <p dir="RTL"><strong><u>(9): السرية وحماية البيانات:</u></strong></p>
    <p dir="RTL">الشركة تستخدم بيانات المستفيد الشخصية بالطريقة المبينة في سياسة الخصوصية التي تعتبر جزءً لا يتجزأ من
      الاتفاقية، وعلى النحو المبين بمزيد من التفصيل في تلك الاتفاقية؛ وبالتالي فإنك بمجرد استخدامك لخدمات مدفوع، فأنت
      تقر على أنك توافق لمدفوع على جمع واستخدام بياناتك الشخصية بالطريقة المبينة في سياسة الخصوصية أو الاتفاقية أو
      التغييرات التي تطرأ عليهما.</p>
    <p dir="RTL"><strong><u>&nbsp;(10): حدود المسؤولية:</u></strong></p>
    <p dir="RTL">10/1 تعتبر مدفوع بمثابة منصة مستقلة، لا تشارك في المعاملات التي تجري بين المستفيد والتاجر أو التعاملات
      الأخرى المشاركة ذات الصلة، ومن ثم لا تتحمل مدفوع المسؤولية بأي حال من الأحوال عن أي وضع من الأوضاع المرتبطة
      بالتجار ولا تتحمل أي مسؤولية عن أي إخفاق يرتبط بمنتجات التجار أو خدماتهم، أو طراز تلك المنتجات/الخدمات، أو
      علامتها، أو مصداقيتها، أو كفاءتها أو أصليتها أو توافرها أو نظاميتها.</p>
    <p dir="RTL">10/2 يعتبر التجار من الغير في الاتفاقية وهم بمثابة متعاقدين مستقلين مع مدفوع فقط ولا يجوز اعتبارهم
      عاملين لدى مدفوع أو ممثلين لها أو وكلاء عنها. كما أن مدفوع تتولى إدارة عملية تنفيذ مهمة الدفع بالآجل من خلال
      منصتها الإلكترونية حصرًا ويتحمل التجار المسؤولية الكاملة عن كافة التعاملات التي تجري بينهم وبين المستفيد أو
      التعاملات الأخرى ذات الصلة، وفي حالة نشوء نزاع بين أي طرف من المشاركين في هذه المعاملات، يتعهد كل من المستفيدين
      والتجار على إعفاء مدفوع من أي مسؤولية ناتجة أو مرتبطة عن أية نزاعاتٍ بين أي طرف بأي حال من الأحوال. والتعهد بإعفاء
      مدفوع (ووكلائها وموظفيها) من المطالبات، والطلبات، والمسؤوليات والتعويضات أياً كان نوعها أو طبيعتها، سواء معروفة أو
      غير معروفة، وسواء أفصح عنها أم لم يفصح عنها، والتي تنشأ عن أو تتعلق بأي شكل من الأشكال بتلك النزاعات.</p>
    <p dir="RTL">10/3 بموجب الموافقة على الاتفاقية تقر بعلمك وتفهمك باحتمال وقوع أخطاء من التجار، ووجود مخاطر ناتجة عن
      التعامل معهم أو من مقدمي الخدمة من الغير، وأن مدفوع لا تتحمل أي مسؤولية تجاهك عن أي من هذه الأخطاء أو المخاطر.&nbsp;</p>
    <p dir="RTL">10/4 لا تتحمل مدفوع أي مسؤولية تجاهك أو تجاه أي شخص آخر عما قد ينشأ من أضرارٍ مباشرة، أو غير مباشرة، أو
      عارضة، أو خاصة، أو جزائية، أو عقابية، أو تبعية، حتى لو أبلغت مدفوع ما يفيد باحتمال وقوع تلك الأضرار، كما لا تتحمل
      مدفوع المسؤولية عن أي أضرار أو التزاماتٍ أو خسائر نشأت من واقع استخدامكم أو اعتمادكم على الخدمات أو جراء عدم
      أمكانية دخولكم على الخدمات أو استخدامها أو من واقع أي معاملة أو علاقة نشأت بينكم وبين أي تاجر من التجار، حتى لو
      ورد لمدفوع ما يفيد باحتمال وقوع تلك الأضرار. ولا تتحمل مدفوع المسؤولية عن أي تأخير أو إخفاق في التنفيذ من الغير،
      كما لا تتحمل المسؤولية في حالة احتمال عدم حيازة التاجر تصريح مهني أو تخصصي بالعمل.</p>
    <p dir="RTL">10/5 يتم تقديم الخدمات كما هي معروضة وحسبما هو متاح منها، وتعفى مدفوع وتبرأ من كافة الإقرارات والضمانات
      الصريحة منها أو الضمنية أو القانونية التي لم يرد نصها صراحة في الشروط الماثلة، ومن ذلك الضمانات المعروضة ضمناً
      بشأن قدرة التاجر وملاءمة خدماته لغرض معين وعدم إخلاله بها، كما لا تقدم مدفوع أي إقرار أو ضمان بشأن مصداقية الخدمات
      أو البضائع أو جودتها أو استدامتها أو توافرها أو مالكها الأصلي هي أو أية خدمات تطلب من خلال استخدام خدمات مدفوع،
      كما لا تضمن مدفوع عدم تعرض الخدمات للتعطيل أو خلوها من الخطأ. ولهذا فأنت تقر وتوافق على تحملك حصرا كامل المسؤولية
      الناشئة عن استخدامك لأي من الخدمات التي تقدمها مدفوع، وذلك إلى أقصى حد يسمح به النظام واجب التطبيق.</p>
    <p dir="RTL">10/6 لا تتحمل مدفوع المسؤولية عن أي تأخير أو إعاقة أو إخفاق في توفير الخدمات، إن كان السبب فيها يرجع
      إلى حدوث قوة قاهرة أو ظروف طارئة أو ما شابهه من أحداث تخرج عن نطاق تحكمنا أو سيطرتنا وكان من شأنها منع عملية تقديم
      الخدمة أو إعاقتها.</p>
    <p dir="RTL">10/7 أنت تقر وتوافق بموجب الاتفاقية على إبراء ذمة شركة مدفوع وأي من شركاتها التابعة ومسؤوليها ومديريها
      وموظفيها ووكلائها من وضد أية مطالبات، أو مسؤوليات، أو أضرار، أو خسائر، أو نفقات، أو تعويضها وإخلاء مسؤوليتها عن
      ذلك، بما في ذلك على سبيل المثال لا الحصر، الرسوم القانونية والمحاسبية الناشئة عن أو بأي طريقة تتعلق بما يلي:</p>
    <p dir="RTL">10/7/1 وصولك إلى حساب مدفوع أو خدمات الدفع من مدفوع أو استخدامها أو عدم القدرة على استخدامها.</p>
    <p dir="RTL">10/7/2 إهمالك أو سوء سلوكك.</p>
    <p dir="RTL">10/7/3 انتهاكك لأي نظام معمول به.</p>
    <p dir="RTL">10/7/4 إخفاقك في تقديم المعلومات الصحيحة والدقيقة والحديثة والكاملة فيما يتعلق بحساب مدفوع والاحتفاظ
      به.</p>
    <p dir="RTL">10/8 إذا لم توافق على أي من هذه الشروط والأحكام في الاتفاقية، يجب عليك الخروج من الموقع وعدم استخدام
      خدمات مدفوع؛ ولا يعفيك ذلك عن مسؤوليتك عن الالتزامات المتعلقة بخدمة مدفوع المقدمة لك سابقًا -إن وجدت-.</p>
    <p dir="RTL"><strong><u>&nbsp;(11): حقوق الملكية الفكرية:</u></strong></p>
    <p dir="RTL">11/1 يعتبر المحتوى الوارد على الموقع الإلكتروني أو التطبيق الخاص بمدفوع ملكية فكرية خاصة بمدفوع أو
      بمرخصيه، ويخضع هذا المحتوى للحماية بموجب حق المؤلف وحقوق العلامات التجارية وبراءات الاختراع وغير ذلك من حقوق
      الملكية الفكرية، ومن ذلك على سبيل المثال لا الحصر، التصاميم، والشعارات، والأيقونات، والصور، والمقاطع الصوتية
      والمرئية، والبرمجة، والشفرات الإلكترونية، وجميع البيانات الأخرى التي تحتويها المنصة.</p>
    <p dir="RTL">11/2&nbsp;يحظر على المستفيد أو أي زائر لمنصة مدفوع استخدام أي علامة تجارية أو أي حقوق ملكية فكرية تخص
      مدفوع، وذلك من أي جزء من الخدمات، كما يحظر عليه إعادة إنتاج الخدمات أو تعديلها أو إجراء أية عملية إعداد، أو نشر،
      أو تنفيذ، أو إرسال، أو عرض، أو بث، أو غير ذلك من وسائل استغلال الخدمات، ما لم تسمح مدفوع بذلك أو تأذن به صراحة
      بشكل كتابي. كما لا يجوز إجراء أعمال تفكيك لنظام الخدمات أو أي هندسة عكسية أو إعادة تشكيل لها، أو القيام بربط أي
      جزء من الخدمات بأي نظام عاكس، أو تأطيره، أو وضع أية برامج، أو نصوص لغرض إزالة أي جزء من الخدمات أو فهرستها أو
      مسحها أو إجراء غير ذلك من عمليات استخراج للبيانات منه، أو القيام بتحميل عملية التشغيل أو العمل الوظيفي لأي شكل من
      أشكال الخدمات بأحمال لا مبرر لها أو القيام بإعاقتها أو محاولة الحصول على إمكانية الدخول عليها دون تصريح بذلك أو
      العمل على الإضرار بالخدمات أو ما يرتبط بها من أنظمة أو شبكات.</p>
    <p dir="RTL"><strong><u>(12): التغيير أو التعديل على الاتفاقية:</u></strong></p>
    <p dir="RTL">12/1 يحق لمدفوع بعد إشعار المستفيد بمدة لا تقل عن ثلاثين (30) يومًا تعديل، أو تغيير، أو إضافة، أو حذف
      المواد من الاتفاقية في أي وقت دون أخذ موافقة من المستفيد، ويعد استمرار المستفيد في استخدام الخدمات محل الاتفاقية
      موافقة ضمنية منه على أي تغيير يطرأ على الاتفاقية.</p>
    <p dir="RTL">12/2 لا يحق للمستفيد التنازل عن أي حقوق أو التزامات منصوص عليها في الاتفاقية دون الحصول على موافقة خطية
      مسبقة من الشركة.</p>
    <p dir="RTL">12/3 يحق لمدفوع أن تتنازل عن الاتفاقية إلى طرف ثالث دون أخذ موافقة من المستفيد.</p>
    <p dir="RTL"><strong><u>(13): سريان وانتهاء الاتفاقية:</u></strong></p>
    <p dir="RTL">13/1 يبدأ العمل بأحكام الاتفاقية اعتباراً من وقت وصول المستفيد إلى منصة مدفوع عبر أي قناة من قنوات
      مدفوع، وتنطبق على أي إجراء يقوم به المستفيد خلال تواجده في المنصة.</p>
    <p dir="RTL">13/2 يجوز للمستفيد إنهاء الاتفاقية في أي وقت، من خلال التوقف عن استخدام منصة مدفوع بشكل كامل، ومع ذلك
      تبقى أي التزامات سابقة في ذمة المستفيد خاضعة للاتفاقية.</p>
    <p dir="RTL">13/3 يحق لمدفوع إنهاء الاتفاقية لأي سبب وفي أي وقت وذلك بعد إشعار المستفيد بمدة لا تقل عن ثلاثين (30)
      يومًا، ويقر ويوافق المستفيد على إنهاء الاتفاقية بسبب أو بدون سبب مع عدم التزماها بإبلاغه بسبب إنهاء الاتفاقية دون
      أدنى التزام على مدفوع تجاه المستفيد.</p>
    <p dir="RTL">13/4 يجوز للمستفيد طلب إغلاق حساب مدفوع الخاص به في أي وقت باتباع التعليمات الموجودة على الموقع
      الإلكتروني أو التطبيق، وتصبح جميع المبالغ المستحقة على المستفيد جراء استخدام خدمة مدفوع واجبة السداد عند إنهاء
      الاتفاقية.</p>
    <p dir="RTL">13/5 في حال عدم موافقة المستفيد على أي تعديلات تطرأ على الاتفاقية من قبل مدفوع، فيكون هذا الاتفاق ملغي
      خلال ثلاثين (30) يوماً من استلام الإشعار بعدم الموافقة، ويبقى المستفيد مسؤولًا عن جميع الالتزامات المتعلقة
      بحسابه.</p>
    <p dir="RTL"><strong><u>(14): عناوين المراسلات والإشعارات:</u></strong></p>
    <p dir="RTL">14/1 عند إنشاء حسابك، فإنك تقر وتوافق على أنك ستتواصل معنا إلكترونياً، وبالتالي توافق على استلام رسائل
      دورية من جانبنا وسوف يتواصل معك فريق مدفوع عبر البريد الإلكتروني أو قد يرسل لكم معلومات عبر رسائل نصية قصيرة،
      وكذلك القيام بنشر إخطارات على الموقع الإلكتروني كجزء من إدارة الأعمال المعتادة والتي ترتبط بعملية استخدامك
      للخدمات، وإنك تقر وتوافق بأن اختياركم عدم تطبيق وسائل التواصل المذكورة هو أمر من شأنه إحداث تأثير على عملية
      استخدامكم للخدمات.</p>
    <p dir="RTL">14/2 يقر المستفيد بعلمه أن أي إخطارات أو إعذارات من مدفوع إليه، تتم عبر العناوين ووسائل التواصل المقدمة
      من جانبه -بما في ذلك عنوان البريد الإلكتروني- وأنها هي العناوين المختارة لاستقبال كافة الإشعارات والمراسلات
      والتبليغات القضائية،&nbsp;</p>
    <p dir="RTL">14/3 يجب أن تكون جميع الإخطارات والمراسلات من المستفيد إلى مدفوع بموجب الاتفاقية كتابةً عبر المراسلات
      إلى البريد الإلكتروني للشركة الموضح على الموقع الإلكتروني.</p>
    <p dir="RTL"><span dir="LTR">&nbsp;14/4 يجب على المستفيد إخطار مدفوع فورًا وبحد أقصى خلال خمسة (5) أيام تقويمية بأي تغيرات تطرأ على عنوانه المعتمد، ووسائل التواصل كرقم الجوال أو البريد الإلكتروني أو أي معلومات أخرى، وفي حال إخلاله بهذا الالتزام تعتبر جميع الإشعارات والمراسلات والتبليغات القضائية التي ترسل إلى عناوينه المشار إليها مستلمة وسارية المفعول نظامًا وقضاءً؛ ويقر بتحمله كامل المسؤولية عن الأضرار المترتبة على إخلاله بهذا الالتزام.&nbsp;</span>
    </p>
    <p dir="RTL"><strong><u>(15): القانون المنطبق:</u></strong></p>
    <p dir="RTL">تخضع هذه الشروط والأحكام والسياسات الواردة في الاتفاقية وتفسر وفقاً لأنظمة المملكة العربية
      السعودية.</p>
    <p dir="RTL"><strong><u>(16): حل النزاعات:</u></strong></p>
    <p dir="RTL">تتم تسوية أي نزاع أو خلاف أو مطالبة تنشأ عن أو تتعلق بالاتفاقية، أو مخالفتها، أو إنهائها، أو بطلانها،
      عن طريق المحكمة أو اللجنة القضائية المختصة في مدينة الرياض، المملكة العربية السعودية.</p>
    <p dir="RTL"><strong><u>(17): الاتفاقية الكاملة</u></strong><span dir="LTR">:</span></p>
    <p dir="RTL">تشكل الاتفاقية، إلى جانب أي تعديلات وأي اتفاقيات إضافية تتعلق باستخدام المنصة والخدمات، الاتفاقية
      الكاملة بينك وبين مدفوع، وقبل إتمام أي معاملة باستخدام خدمات مدفوع، يجب عليك الموافقة على هذه الاتفاقية، ويعني
      النقر فوق الزر "<strong>إتمام العملية</strong>" إلى أنك قرأت الاتفاقية وفهمتها وقبلتها بالكامل وأنك ملتزم بدفع
      المبلغ الإجمالي المستحق لمدفوع</p>
    <p dir="RTL"><strong><u>(18): معلومات التواصل:</u></strong></p>
    <p dir="RTL">إذا كان لديك أي أسئلة بخصوص الاتفاقية أو منصة مدفوع، يرجى منك زيارة صفحة "المساعدة" للحصول على معلومات
      الاتصال الخاصة بنا.</p>
  </div>
</div>
<div class="terms-data" *ngIf="currentLang == ApplicationLanguages.En">
  <div class="container">
    <p class="heading center first-item" style="text-align: center !important;">
    <p dir="RTL"><span>اتفاقية "</span><strong><span>مدفوع</span></strong><span>" (الشروط والأحكام)</span></p>
    <strong>
      <p class="heading center">تاريخ السريان 29/12/2024م</p>
    </strong>
    <p dir="RTL">
      <strong><label class="heading center" style="text-align: center !important;">التمهيد:</label></strong>
    </p>
    <p dir="RTL">شركة مدفوع العربية للدفع الآجل، شركة مساهمة غير مدرجة، سجل تجاري رقم (١٠١٠٧٤٧٠٧٧) المسجل في مدينة
      الرياض، بالمملكة العربية السعودية، ويشار إليها فيما بعد بـ("<strong>نحن أو الشركة أو مدفوع أو المنصة</strong>")،
      هي شركة مصرح لها من البنك المركزي لممارسة نشاط الدفع الآجل (<strong><span dir="LTR">BNPL</span></strong>)
      ("<strong>الخدمات</strong>")، وذلك من خلال موقع مدفوع الإلكتروني أو تطبيق مدفوع أو المتاجر الإلكترونية أو الفعلية
      التي تتيح الدفع عن طريق مدفوع ("<strong>قنوات مدفوع</strong>")؛ وينحصر دور مدفوع في تسهيل عملية دفع قيمة العمليات
      الشرائية التي تتم بين الأشخاص والمتاجر، ولا تمتد مسؤولية مدفوع إلى أي من مسؤوليات هؤلاء الأشخاص أو المتاجر
      المعتادة تجاه بعضهما البعض.&nbsp;</p>
    <p dir="RTL">منصة مدفوع ليست متجر، وهي غير مسؤولة عن دقة المعلومات المعروضة -فيها أو خارجها- من المتاجر أو نظامية
      المنتجات أو الخدمات المقدمة من المتاجر المشاركين. كما أن منصة مدفوع ليست جهة تحقق من معلومات أو هويات الأشخاص
      المستفيدين من الخدمة. وفي جميع الأحوال تتحمل المتاجر ويتحمل الأشخاص كامل المسؤوليات المتعلقة بعمليات الشراء التي
      تجري على المنصة فيما بينهما.</p>
    <p dir="RTL">وحيث إنك ترغب في الاستفادة من الخدمات المقدمة من مدفوع، فإنك تُقر بمجرد وصولك أو استخدامك لأي من خدمات
      مدفوع أو تسجيل حساب مستخدم بأنك اطلعت على هذه الشروط والأحكام وفهمتها ووافقت على الالتزامات الواردة فيها، كما تقر
      بعلمك أن هذه الشروط والأحكام عقد إلزامي بينك وبين مدفوع؛ وقد تم إبرامه بين الطرفين بكامل أهليتهما الشرعية
      والنظامية على الآتي:</p>
    <p dir="RTL"><strong><u>&nbsp;(1): أهمية التمهيد:&nbsp;</u></strong></p>
    <p dir="RTL">يعتبر التمهيد أعلاه جزءً لا يتجزأ من الاتفاقية ومكملاً لها وتفسر بنودها وفقاً له.</p>
    <p dir="RTL"><strong><u>&nbsp;(2): أطراف الاتفاقية:</u></strong></p>
    <p dir="RTL"><span dir="LTR">2/1 تعتبر الاتفاقية عقد مبرم بين مدفوع وبين كل من يستفيد أو يصل لخدمات مدفوع ("<strong>المستفيد</strong>") عبر أي من قنوات مدفوع.</span>
    </p>
    <p dir="RTL"><span dir="LTR">2/2</span>&nbsp;لا يعتبر التاجر الذي يشتري منه المستفيد سلع أو خدمات باستخدام خدمات
      مدفوع طرفاً في الاتفاقية، وتعد أي شروط سارية بين التاجر والمستفيد أو التاجر ومن يتعاقد معه لتنفيذ التزاماته أمام
      المستفيد أو التاجر ومدفوع مستقلة عن الاتفاقية.</p>
    <p dir="RTL"><strong><u>&nbsp;(3): التعريفات:&nbsp;</u></strong></p>
    <p dir="RTL">لأغراض تطبيق الاتفاقية، يقصد بالكلمات والعبارات الآتية &ndash;أينما وردت في الاتفاقية&ndash; المعاني
      المبينة أمام كل منها، ما لم يقتضِ السياق غير ذلك:</p>
    <p dir="RTL"><strong>3/1 الاتفاقية</strong>: هذا العقد المبرم بين مدفوع والمستفيد بما في ذلك الشروط والأحكام الواردة
      فيه وسياسة الخصوصية الخاصة بمدفوع وأي تحديثات تطرأ عليهما من وقت إلى آخر، وأي شروط وأحكام أخرى يشار إلى أنها جزء
      من الاتفاقية.</p>
    <p dir="RTL"><strong>3/2 الشركة أو مدفوع</strong>: شركة مدفوع العربية للدفع الآجل، سجل تجاري رقم (١٠١٠٧٤٧٠٧٧) المسجل
      في مدينة الرياض، بالمملكة العربية السعودية ("<strong>مدفوع</strong>")، وقد يشار إليها بالضمير
      ("<strong>نحن</strong>") أو نون الجماعة أو أي ضمائر أخرى مماثلة في الاتفاقية.</p>
    <p dir="RTL"><strong>3/3 المستفيد</strong>: الشخص ذو الصفة الطبيعية الذي يستخدم أي من خدمات مدفوع لإتمام عملية شراء
      مع أي من المتاجر المشاركة، وقد يشار إليه بضمير المخاطب ("<strong>أنت</strong>") أو (<strong>"ك</strong>") أو أي
      ضمائر أخرى مماثلة في الاتفاقية.</p>
    <p dir="RTL"><strong>3/4 التاجر أو المتاجر المشاركة</strong>: المتجر -الإلكتروني أو التطبيقات أو المتاجر الفعلية-
      التي توفر خدمات السداد عبر مدفوع، أو المتاجر المعروضة في منصة مدفوع، والتي يعرض فيها التاجر السلع والخدمات
      والمنتجات الخاصة به وغيرها، وفقاً للاتفاقية بينه وبين مدفوع.&nbsp;</p>
    <p dir="RTL"><strong>3/5 قنوات مدفوع</strong>: الوسيلة التي يقدمها التاجر للمستفيد لتمكين المستفيد من اختيار مدفوع
      كوسيلة دفع ويشمل ذلك على سبيل المثال لا الحصر الباركود الموضح في الفاتورة الذي ينقل المستفيد لصفحة الدفع الخاصة في
      مدفوع.</p>
    <p dir="RTL"><strong>3/6 الموقع الإلكتروني</strong>: الموقع الإلكتروني الخاص بشركة مدفوع WWW.MADFU.COM.SA</p>
    <p dir="RTL"><strong>3/7 التطبيق</strong>: تطبيق مدفوع المتوفر على (Apple App Store) أو (Google Play Store) أو أي
      نظام تشغيل آخر يتوفر فيه التطبيق الخاص بمنصة مدفوع.</p>
    <p dir="RTL"><strong>3/8 حساب مدفوع</strong>: الحساب الذي أنشأه المستفيد ليتمكن من استخدام خدمات مدفوع حسب الشروط
      والأحكام المنصوص عليها في الاتفاقية.</p>
    <p dir="RTL"><strong>3/9 محفظة مدفوع</strong>: محفظة مالية إلكترونية مرتبطة بحسابك في مدفوع، يتم إضافة الاسترداد
      النقدي و قيمة المسترجعات بها.</p>
    <p dir="RTL"><strong>3/10 خدمات الدفع من مدفوع</strong>: تسهيل إجراءات الدفع الآجل للأشخاص عبر الدفع بالتقسيط.</p>
    <p dir="RTL"><strong><u>(4): إقرارات المستفيد:</u></strong></p>
    <p dir="RTL">4/1 يقر المستفيد أنه بكامل الأهلية المعتبرة شرعاً ونظاماً لإبرام الاتفاقية وفق الشروط والأحكام المنصوص
      عليها، وأنه أهل للتصرفات القانونية اللازمة لفتح حساب أو استخدام خدمات مدفوع.</p>
    <p dir="RTL">4/2 يقر المستفيد بعلمه أن الوصول إلى منصة مدفوع أو استخدام أي من خدمات مدفوع أو النقر بالموافقة على
      الاتفاقية أو الشروط والأحكام إقراراً منه على إبرام الاتفاقية إلكترونياً بينه وبين مدفوع حسب أنظمة المملكة العربية
      السعودية.</p>
    <p dir="RTL">4/3 يقر المستفيد أن مدفوع تملك كامل الصلاحية في تحديث الاتفاقية وتقع عليه مسؤولية مراجعة المعلومات
      المنشورة على تطبيقنا أو موقعنا الإلكتروني بانتظام ويستعرض الاتفاقية بانتظام قبل استخدامه لأي من خدمات مدفوع.
      واستمرار استخدامه للخدمات على الشروط يعبر عن موافقته على الشروط والتعديلات المستحدثة عليها.</p>
    <p dir="RTL">4/4 يقر المستفيد أنه يفوض مدفوع في استخدام أي من أو جميع معلومات التعريف والبيانات الشخصية الخاصة به
      التي تم جمعها من خلال المواقع الإلكترونية أو المنصات أو تطبيقات الهواتف المحمولة الخاصة بمدفوع أو التجار من وقتٍ
      لآخر ويجوز استخدامها أو الإفصاح عنها إلى أشخاص آخرين وفقًا لسياسة الخصوصية الخاصة بمدفوع. وقد يشمل ذلك مشاركة
      معلومات التعريف الخاصة به وأية معلومات أخرى مع أطراف خارجية لأغراض التحقق من الائتمان ومكافحة غسل الأموال أو غيرها
      من الفحوصات المماثلة أو لأي غرض آخر تعتبره مدفوع ضروري من أجل تمكينها من تقديم خدمات الدفع للمستفيد والالتزام
      بجميع الأنظمة واللوائح المعمول بها.</p>
    <p dir="RTL">4/5 يقر المستفيد أنه مقيم في المملكة العربية السعودية ولديه عنوان إقامة دائم فيها.</p>
    <p dir="RTL">4/6 يقر المستفيد ويوافق على تحديث معلومات حسابه لدينا ودقتها. ويقر بأن أي عنوان سكني أو عنوان بريد
      إلكتروني أو رقم هاتف جوال يقدمه لنا هو عنوان صحيح يخصّه وقت تقديمه. ويتحمل المستفيد مسؤولية إخطار مدفوع بشكل فوري
      عند تغيير أي من المعلومات الواردة أعلاه وكما يحق لشركة مدفوع طلب تحديث بيانات المستخدم أو طلب بيانات إضافية سواءً
      من المستخدم أو أية أطراف أخرى على سبيل المثال (سمة).</p>
    <p dir="RTL">4/7 يتعهد المستفيد أنه سيقوم بسداد جميع التزاماته المالية الناجمة عن عمليات الشراء الخاصة به، وفقاً
      لشروط الدفع المتفق عليها وقت الشراء.</p>
    <p dir="RTL">4/8 يقر المستفيد أن الشركة تمتلك الحق في أي وقت، دون الحاجة لإخطاره، ببيع، أو إسناد، أو نقل ملكية
      حسابه، أو أي مبالغ مستحقة على حسابه، أو عقده معنا، أو أي من حقوقنا أو التزاماتنا تجاهه بموجب حسابه أو بموجب هذه
      الشروط إلى أي شخص أو كيان آخر، وفي هذه الحالة تنتقل كافة الحقوق والالتزامات المتعلقة بالحساب بموجب هذه الشروط إلى
      هذا الشخص أو الكيان.</p>
    <p dir="RTL">4/9 يقر المستفيد ويتعهد بعدم استخدام خدمة مدفوع لأي أغراض محرمة أو غير قانونية كشراء منتجات أو خدمات
      ممنوعة بموجب الأنظمة السارية في المملكة العربية السعودية.</p>
    <p dir="RTL">4/10 يقر المستفيد بأنه مسؤول عن جميع الخسائر التي قد تتكبدها مدفوع نتيجة لاستخدام حسابه في مدفوع من قبل
      أي شخص أخر حصل عليه بموافقته.&nbsp;</p>
    <p dir="RTL">4/11 يقر المستفيد بأن مدفوع لن تكون مسؤولة عن رفض أي متجر لقبول مدفوع كخيار دفع/ كما أنها لن تكون
      مسؤولة بأي طريقة عن البضائع والخدمات المقدمة من المتاجر، وللمستفيد حل أي من تلك الشكاوي مباشرة مع المتجر، ولن
      يترتب على مدفوع أي مسؤولية في هذا الخصوص ولن تكون أي دعوى من المستفيد ضد المتجر موضوع مطالبه تجاه مدفوع، ولن تقوم
      مدفوع برد أي مبلغ استرداد إلا بعد أن تتلقى من المتجر طلب إرجاع صادر صحيح بشأن العملية المُسترجعة سواء كليًا أو
      جزئيًا.&nbsp;</p>
    <p dir="RTL"><strong><u>(5) التسجيل وإنشاء حساب:</u></strong></p>
    <p dir="RTL">5/1 حتى يتسنى لك الاستفادة من خدمات مدفوع، عليك أولاً استكمال بيانات التسجيل الخاصة بمدفوع وفتح حساب
      مدفوع حسب المتطلبات الواردة في صفحة التسجيل.</p>
    <p dir="RTL">5/2 يلتزم المستفيد بتقديم معلوماته إلى مدفوع حسب متطلبات البنك المركزي السعودي (اعرف عميلك) والتي
      تتضمن:</p>
    <ul dir="rtl">
      <li>الاسم الرباعي.</li>
      <li>رقم الهوية الوطنية أو رقم الإقامة بالمملكة العربية السعودية.</li>
      <li>العمر.</li>
      <li>العنوان الوطني.</li>
      <li>وسائل التواصل: البريد الإلكتروني، ورقم الهاتف.&nbsp;</li>
      <li>أي معلومات أخرى تقررها مدفوع من وقت لآخر أو تقررها الأنظمة المرعية.&nbsp;</li>
    </ul>
    <p dir="RTL"><span dir="LTR">5/3 يقر المستفيد على صحة المعلومات المقدمة منه ويتحمل مسؤولية أي خطأ في المعلومات، ويقر بموافقته على حفظ المعلومات واستخدامها وفقاً لهذه الأحكام وأحكام سياسة الخصوصية الخاصة بمدفوع.&nbsp;</span>
    </p>
    <p dir="RTL"><span dir="LTR">5/4 يفوض مدفوع بأن تحصل على ما يلزم أو تحتاج إليه من معلومات عنه، من الشركة السعودية للمعلومات الائتمانية (سمة) أو غيرها. كما ويوافق على أن تفصح مدفوع عن المعلومات الائتمانية الخاصة به للشركة السعودية للمعلومات الائتمانية (سمة) أو غيرها، من خلال اتفاقية العضوية المبرمة وقواعد العمل المقرة والخاصة بتبادل المعلومات ولأي جهة أخرى يوافق عليها البنك المركزي السعودي (ساما).</span>
    </p>
    <p dir="RTL"><span dir="LTR">5/5 يقر المستفيد بعلمه أن عدم التزامه بسداد الالتزامات المستحقة سيؤثر على سجله الائتماني لدى الشركة السعودية للمعلومات الائتمانية (سمة) أو غيرها.</span>
    </p>
    <p dir="RTL">5/6 يتحمل المستفيد كامل المسؤولية عن أي نشاط يجري في حسابه لدى مدفوع، ولا يجوز له نقل الحساب أو السماح
      للغير باستخدامه، ويجب على المستفيد الحفاظ على كلمة المرور الخاصة بحساب مدفوع، وإخطار مدفوع فوراً في حال فقدان أو
      سرقة كلمة المرور الخاصة به، أو إذا كان يعتقد أنه تم الوصول إلى حسابه من قبل الغير، كما يتحمل المستفيد مسؤولية أي
      معاملات تتم باستخدام حسابه المسروق ما لم يبادر على الفور بإخطار مدفوع بإيقاف الحساب، وتلقيه رسالة من مدفوع تفيد
      بتأكيد إيقاف الحساب.</p>
    <p dir="RTL">5/7 يحق للشركة إيقاف الخدمة عن المستفيد وإيقاف عملية دخوله على الموقع الإلكتروني، أو التطبيق، أو
      خدماته، أو غيرها، أو تحديد الدخول، أو قصره، في حال ثمة شكوك لدى مدفوع بأن أية معلومة من المعلومات التي قدمها
      المستفيد هي معلومة غير صحيحة، أو غير دقيقة، أو غير كاملة، أو غير محدثة، أو في حال الاشتباه بغسل الأموال أو أي
      عمليات مشبوهة ويحق لمدفوع إجراء عمليات التحقيق والتحري عند الاشتباه بالاحتيال أو إساءة الاستعمال لأنظمة مدفوع
      ويشمل ذلك التعامل مع جهة ثالثة لهذا الغرض. دون إخلال بأي حقوق أخرى وتعويضات مكفولة لمدفوع وفق الشروط والأحكام هذه
      أو بمقتضى الأنظمة المرعية.</p>
    <p dir="RTL"><strong><u>(6): الخدمات المقدمة:</u></strong></p>
    <p dir="RTL">6/1 تتيح لك خدمة الدفع الآجل من مدفوع دفع التكلفة الاجمالية للمنتج أو الخدمة "المبلغ الإجمالي" من خلال
      تقسيم المبلغ إلى عدة أقساط بمبالغ محددة متفق عليها "الأقساط" مقسمة على فترات، ويتم دفعها في تواريخ محددة متفق
      عليها "تواريخ الاستحقاق"، عند إتمامك لطلب الدفع من خلال مدفوع.</p>
    <p dir="RTL">6/2 تراجع مدفوع معلوماتك وتتحقق منها من خلال مصدر موثوق مستقل قبل الموافقة على طلب الاستفادة من خدمة
      الدفع الآجل.</p>
    <p dir="RTL">6/3 لمدفوع الحق في رفض طلبك للاستفادة من خدمة الدفع الآجل في أي وقت ودون إبداء أي أسباب.</p>
    <p dir="RTL">6/4 تتيح مدفوع خدمة الدفع الآجل فقط للأشخاص المؤهلين، وتحدد الأهلية وفقاً لتقدير مدفوع عند محاولتك
      الشراء باستخدام الخدمة، وقد يعتمد كذلك على عامل أو عدة عوامل، مثل تقييمنا للجدارة الائتمانية وسجل معاملاتك، وكذلك
      سجل حساب التاجر، أو أي سبب آخر. وفي كافة الأحوال يجب أن تستوفي المعايير التالية لتعد شخصًا مؤهلًا:&nbsp;</p>
    <ol dir="RTL">
      <li>لديك الأهلية اللازمة والمعتبرة شرعاً ونظامًا للموافقة على هذه الشروط والاحكام.</li>
      <li>تبلغ من العمر 18 عام هجري أو أكثر.</li>
      <li>أن تكون مقيم فالمملكة العربية السعودية ولديك عنوان إقامة دائم.</li>
      <li>أن يكون لديك رقم هاتف محمول نشط مسجل باسمك.</li>
      <li>أن يكون لديك بريد إلكتروني نشط مسجل باسمك.</li>
      <li>أن تقدم جميع المعلومات والوثائق التي تطلبها مدفوع منك.</li>
    </ol>
    <p dir="RTL">6/5 تتيح لك خدمة الدفع الآجل من مدفوع السداد عن طريق وسائل الدفع المتاحة من أطراف ثالثة مثل: مدى، ماستر
      كارد، فيزا، وغيرها. ويمكن الاستفادة من هذه الخدمات عن طريق منصة مدفوع مباشرة &ndash;سواء عبر الموقع الإلكتروني أو
      التطبيق&ndash; وكذلك يمكن الاستفادة منها عن طريق المتاجر الإلكترونية أو الفعلية المشاركة.</p>
    <p dir="RTL">6/6 يحق لمدفوع أن تضع حد إجمالي لخدمة الدفع الآجل، وهذا الحد هو إجمالي المبلغ المتاح لك لعمليات الشراء
      عن طريق الدفع الآجل وفقاً لتقييمك الائتماني لدى مدفوع والشركة السعودية للمعلومات الائتمانية ("سمة"). ويحق لمدفوع
      تعديل الحد الإجمالي للدفع سواءً بالزيادة أو النقصان بناءً على تقديرها.</p>
    <p dir="RTL">6/7 يحق لشركة مدفوع سحب موافقتها على عملية شرائية معتمدة في أي وقت في حالة الاشتباه بالاحتيال أو في
      حالة النزاع أو لأي أسباب منصوص عليها في الاتفاقية، ويتم سحب موافقة مدفوع تلقائياً إذا تم تغيير أي من شروط المبيعات
      من جانب التاجر فيما يتعلق بالعملية الشرائية المعتمدة دون موافقة مدفوع، وذلك دون إخلال بأية حقوق أخرى وتعويضات
      مكفولة لمدفوع وفق الشروط والأحكام هذه أو بمقتضى الأنظمة المرعية.</p>
    <p dir="RTL"><strong><u>&nbsp;(7): المدفوعات</u></strong></p>
    <p dir="RTL">7/1 بموجب الاتفاقية فإن مدفوع ملزمة بإيضاح مقدار مبالغ الأقساط المتعين عليك سدادها وعددها، ومددها عن كل
      عملية شراء، كما أنها ملزمة بتذكيرك &ndash;على بيانات التواصل المقدمة منك&ndash; بتاريخ سداد كل قسط قبل حلوله.&nbsp;</p>
    <p dir="RTL">7/2 بموجب الاتفاقية فإنك تقر بعلمك والتزامك في سداد جميع الالتزامات المالية والأقساط الناجمة عن عمليات
      الشراء الخاصة بك، وفقاً لشروط الدفع المتفق عليها وقت الشراء.</p>
    <p dir="RTL">7/3 يقر المستفيد بأن لمدفوع الحق في طلب تسجيل أكثر من بطاقة أو مصدر تمويل واحد في حساب مدفوع لاستخدامها
      فيما يتعلق بسداد الأقساط المستحقة، وسيطلب منه تحديد خيار افتراضي بحيث يتم استقطاع المبالغ المستحقة المتفق عليها
      &ndash;بما في ذلك الدفعة الأولى المقدمة أو أي قسط مستحق&ndash; لكل عملية شرائية في تاريخ الاستحقاق المحدد في جدول
      الدفعات.&nbsp;</p>
    <p dir="RTL">7/4 يقر المستفيد بعلمه أن مدفوع -وفقًا لتقديرها- تملك الحق بتحديد أي طرق دفع مقبولة، ويجوز لها عدم قبول
      أي طريقة دفع معينة في أي وقت ولأي سبب.</p>
    <p dir="RTL">7/5 بموجب الاتفاقية فإنك تفوض مدفوع في التعامل مع جهات أخرى فيما يتعلق بخدمات السداد، بما في ذلك بطاقات
      الخصم أو الائتمان، وتلتزم مدفوع في عدم تخزين بطاقات الائتمان في نظامها.</p>
    <p dir="RTL">7/6 تقر بعلمك أن مدفوع ليست مسؤولة عن أي أضرار ناتجة عن أي أخطاء أو إشكاليات من الجهات الأخرى فيما
      يتعلق بدفع المبالغ المستحقة وغيرها. ولا تفرض مدفوع أي رسوم فيما يتعلق بالتعامل مع الجهات الأخرى (إلا إذا تم النص
      على ذلك).</p>
    <p dir="RTL">7/7 يقر المستفيد بالتزامه في أن تحتوي البطاقة أو مصدر التمويل المقدم منه على المبالغ اللازمة لسداد كامل
      الالتزامات المالية وأن تظل صالحة حتى سداد كافة المستحقات.</p>
    <p dir="RTL">7/8 في حال تعذر تحصيل الأقساط المستحقة من البطاقة الافتراضية، يقر المستفيد أنه يمنح مدفوع الصلاحية
      الكاملة في تحصيل الأقساط المستحقة من أي أو كافة البطاقات أو مصادر التمويل المسجلة في حساب مدفوع، وذلك بحسب تقدير
      مدفوع. &nbsp;</p>
    <p dir="RTL">7/9 في حال لم تتمكن مدفوع أو من ينوب عنها في سحب القسط المستحق وفقًا للفقرة السابقة، فإن المستفيد يقر
      بعلمه بوجوب سداد القسط المتأخر لمدفوع على الفور.&nbsp;</p>
    <p dir="RTL">7/10 يقر المستفيد بتنازله عن أي حقوق في إجراء عمليات رد المبالغ المدفوعة أو عمليات الإلغاء، وذلك في
      حالة استخدام طرق دفع قابلة للإلغاء كبطاقة الائتمان.</p>
    <p dir="RTL">7/11 يقر المستفيد بعلمه بالأضرار والعواقب المحتملة عند استخدام خدمة الدفع الآجل من مدفوع بمخالفة الشروط
      المتفق عليها، ويوافق على أحقية مدفوع في حالة تأخرك أو عجزك عن السداد في إخطار الشركة السعودية للمعلومات الائتمانية
      (سمة) أو غيرها، وأحقية مدفوع في منعه من استخدام حساب مدفوع أو الحد منه، مع بقائه في جميع الأحوال مسؤولاً مسؤولية
      كاملة عن المبالغ المستحق دفعها لمدفوع.</p>
    <p dir="RTL">7/12 يقر المستفيد أن مدفوع غير ملتزمة برد المدفوعات المختلف عليها مع التاجر. ومع ذلك، &nbsp;قد تساعد
      مدفوع -بالقدر المستطاع- في عمليات استرداد المدفوعات وإعادة المبالغ من المتاجر في بعض الحالات.</p>
    <p dir="RTL">7/13 يحق للمستفيد بموجب الاتفاقية أن يسدد إجمالي المبلغ المستحق قبل حلوله كليًا أو جزئيًا، دون أي رسوم
      إضافية للدفع المسبق .</p>
    <p dir="RTL">7/14 يحق لمدفوع التعامل مع أطراف ثالثة بغرض التعامل مع حالات تأخر المستفيد عن الدفع، ويشمل ذلك على سبيل
      المثال لا الحصر الأطراف الثالثة التي تعمل في أنشطة تحصيل أو شراء الديون.&nbsp;</p>
    <p dir="RTL">7/15 يقر المستفيد بعلمه أنه في حال عدم سداد المستحقات في تاريخ استحقاقها وفقًا لجدول الدفعات، فإنه
      يتحمل كافة التكاليف التي تتكبدها مدفوع لأغراض تحصيل مستحقاتها، ومن ذلك التكاليف القضائية، وأتعاب المحاماة، وأتعاب
      شركات تحصيل الديون وأي تكلفة أخرى تتكبدها مدفوع لأغراض تحصيل مستحقاتها.</p>
    <p dir="RTL">7/16 تلتزم مدفوع بإعفائك من المبالغ المطالب بها لصالحها في حالة الوفاة أو العجز الكلي وذلك خلال مدة
      أقصاها ثلاثين يوم من تاريخ استلام المستندات المثبتة لذلك وإعادة ما تم خصمه بالزيادة من تاريخ الوفاة أو العجز
      الكلي، ويستثنى من ذلك حالات الوفاة أو العجز الكلي الناتجة عن أي من الآتي:&nbsp;</p>
    <p dir="RTL">7/16/1 تعمد المستفيد إصابة ذاته، او محاولته للانتحار &ndash;سواء كان عاقلاً او مختلًا عقلياً&ndash; في
      ذلك الوقت.</p>
    <p dir="RTL">7/16/2 الكوارث الطبيعية والحروب.</p>
    <p dir="RTL">7/16/3 قرارات المحكمة أو السلطة القضائية المختصة بموجب الأنظمة السارية في المملكة العربية السعودية.</p>
    <p dir="RTL">7/16/4 تعاطي الكحول أو المخدرات أو المواد الممنوعة.</p>
    <p dir="RTL">7/16/5 الاشتراك أو التدريب على أي رياضة أو منافسة خطرة، كالاشتراك في سباقات الخيل أو سباقات
      السيارة.</p>
    <p dir="RTL">7/16/6 وفاة أو إصابة ناشئة عن طبيعة العمل.&nbsp;</p>
    <p dir="RTL"><strong><u>(8): العروض والمكافآت:</u></strong></p>
    <p dir="RTL">8/1 قد تقرر الشركة إجراء عروض ترويجية لبعض أو كل المتاجر، وذلك عن طريق تقديم مكافآت تحفيزية لإجراء
      المشتريات من بعض أو كل المتاجر أو لتنشيط المنصة في حال موافقة المتاجر المشتركة. وقد تكون تلك المكافآت على شكل
      استرداد جزء أو كامل مبلغ المشتريات عن طريق خدمات مدفوع، وذلك بإيداع مبالغ في محفظة مدفوع الخاصة
      بالمستفيد.&nbsp;</p>
    <p dir="RTL">8/2 يقر المستفيد بأن تلك المكافآت والمبالغ ليست قابلة للاسترداد النقدي، وخاضعة لفترة صلاحية محددة من
      قبل مدفوع.</p>
    <p dir="RTL">8/3 الشروط الخاصة في الاسترداد النقدي بمحفظة مدفوع الخاصة بك:</p>
    <p dir="RTL">8/3/1 يقر المستفيد بأن الحصول على الاسترداد النقدي مقتصر على الطلبات التي سددت بالكامل فقط، كما يقر بأن
      يستثنى من مبلغ الاسترداد قيمة ضريبة القيمة المضافة، ورسوم الخدمات ونقاط البيع.</p>
    <p dir="RTL">8/3/2 يقر المستفيد بأن مبلغ الاسترداد النقدي المودع في محفظته لدى مدفوع لا يمكن استخدامه للإيفاء
      بالتزاماته في مواجهة مدفوع، وينحصر استخدام مبلغ الاسترداد النقدي في عمليات الشراء الجديدة عن طريق خدمات مدفوع.</p>
    <p dir="RTL">8/3/3 يقر المستفيد بأن مدة صلاحية استخدام الاسترداد النقدي في محفظته لدى مدفوع هي ستة (6) أشهر، كما يحق
      لمدفوع وفقاً لتقديرها المطلق التعديل على مدة الصلاحية من حين لأخر على أن تخطر المستفيد قبل تفعيل التعديل بمدة لا
      يقل 15 يوم.</p>
    <p dir="RTL">8/3/4 لمدفوع وضع حد أعلى لقيمة الاسترداد النقدي وفقًا لتقديرها.</p>
    <p dir="RTL">8/3/5 تحتفظ مدفوع بالحق في عدم إضافة قيمة أي استرداد نقدي مكتسب أو حذف أي استرداد نقدي متراكم على
      محفظتك في مدفوع؛ حال قيامك بإساءة استخدام خدمات مدفوع.</p>
    <p dir="RTL">8/3/6 تحتفظ مدفوع بحقها في عدم تفعيل الاسترداد النقدي على العمليات الشرائية لفئة مختارة من التجار.</p>
    <p dir="RTL">8/3/7 الاسترداد النقدي له قيمة نقدية؛ ولا يمكن استردادها إلا في صورة رصيد يضاف إلى محفظتك.&nbsp;</p>
    <p dir="RTL">8/3/8 يجوز لنا تعديل الشروط المنصوص عليها في هذا البند (8/3) أو إلغاء اكتسابك للاسترداد النقدي في أي
      وقت بحسب أحكام تعديل الاتفاقية الواردة في المادة (12) من الاتفاقية.&nbsp;</p>
    <p dir="RTL"><strong><u>(9): السرية وحماية البيانات:</u></strong></p>
    <p dir="RTL">الشركة تستخدم بيانات المستفيد الشخصية بالطريقة المبينة في سياسة الخصوصية التي تعتبر جزءً لا يتجزأ من
      الاتفاقية، وعلى النحو المبين بمزيد من التفصيل في تلك الاتفاقية؛ وبالتالي فإنك بمجرد استخدامك لخدمات مدفوع، فأنت
      تقر على أنك توافق لمدفوع على جمع واستخدام بياناتك الشخصية بالطريقة المبينة في سياسة الخصوصية أو الاتفاقية أو
      التغييرات التي تطرأ عليهما.</p>
    <p dir="RTL"><strong><u>&nbsp;(10): حدود المسؤولية:</u></strong></p>
    <p dir="RTL">10/1 تعتبر مدفوع بمثابة منصة مستقلة، لا تشارك في المعاملات التي تجري بين المستفيد والتاجر أو التعاملات
      الأخرى المشاركة ذات الصلة، ومن ثم لا تتحمل مدفوع المسؤولية بأي حال من الأحوال عن أي وضع من الأوضاع المرتبطة
      بالتجار ولا تتحمل أي مسؤولية عن أي إخفاق يرتبط بمنتجات التجار أو خدماتهم، أو طراز تلك المنتجات/الخدمات، أو
      علامتها، أو مصداقيتها، أو كفاءتها أو أصليتها أو توافرها أو نظاميتها.</p>
    <p dir="RTL">10/2 يعتبر التجار من الغير في الاتفاقية وهم بمثابة متعاقدين مستقلين مع مدفوع فقط ولا يجوز اعتبارهم
      عاملين لدى مدفوع أو ممثلين لها أو وكلاء عنها. كما أن مدفوع تتولى إدارة عملية تنفيذ مهمة الدفع بالآجل من خلال
      منصتها الإلكترونية حصرًا ويتحمل التجار المسؤولية الكاملة عن كافة التعاملات التي تجري بينهم وبين المستفيد أو
      التعاملات الأخرى ذات الصلة، وفي حالة نشوء نزاع بين أي طرف من المشاركين في هذه المعاملات، يتعهد كل من المستفيدين
      والتجار على إعفاء مدفوع من أي مسؤولية ناتجة أو مرتبطة عن أية نزاعاتٍ بين أي طرف بأي حال من الأحوال. والتعهد بإعفاء
      مدفوع (ووكلائها وموظفيها) من المطالبات، والطلبات، والمسؤوليات والتعويضات أياً كان نوعها أو طبيعتها، سواء معروفة أو
      غير معروفة، وسواء أفصح عنها أم لم يفصح عنها، والتي تنشأ عن أو تتعلق بأي شكل من الأشكال بتلك النزاعات.</p>
    <p dir="RTL">10/3 بموجب الموافقة على الاتفاقية تقر بعلمك وتفهمك باحتمال وقوع أخطاء من التجار، ووجود مخاطر ناتجة عن
      التعامل معهم أو من مقدمي الخدمة من الغير، وأن مدفوع لا تتحمل أي مسؤولية تجاهك عن أي من هذه الأخطاء أو المخاطر.&nbsp;</p>
    <p dir="RTL">10/4 لا تتحمل مدفوع أي مسؤولية تجاهك أو تجاه أي شخص آخر عما قد ينشأ من أضرارٍ مباشرة، أو غير مباشرة، أو
      عارضة، أو خاصة، أو جزائية، أو عقابية، أو تبعية، حتى لو أبلغت مدفوع ما يفيد باحتمال وقوع تلك الأضرار، كما لا تتحمل
      مدفوع المسؤولية عن أي أضرار أو التزاماتٍ أو خسائر نشأت من واقع استخدامكم أو اعتمادكم على الخدمات أو جراء عدم
      أمكانية دخولكم على الخدمات أو استخدامها أو من واقع أي معاملة أو علاقة نشأت بينكم وبين أي تاجر من التجار، حتى لو
      ورد لمدفوع ما يفيد باحتمال وقوع تلك الأضرار. ولا تتحمل مدفوع المسؤولية عن أي تأخير أو إخفاق في التنفيذ من الغير،
      كما لا تتحمل المسؤولية في حالة احتمال عدم حيازة التاجر تصريح مهني أو تخصصي بالعمل.</p>
    <p dir="RTL">10/5 يتم تقديم الخدمات كما هي معروضة وحسبما هو متاح منها، وتعفى مدفوع وتبرأ من كافة الإقرارات والضمانات
      الصريحة منها أو الضمنية أو القانونية التي لم يرد نصها صراحة في الشروط الماثلة، ومن ذلك الضمانات المعروضة ضمناً
      بشأن قدرة التاجر وملاءمة خدماته لغرض معين وعدم إخلاله بها، كما لا تقدم مدفوع أي إقرار أو ضمان بشأن مصداقية الخدمات
      أو البضائع أو جودتها أو استدامتها أو توافرها أو مالكها الأصلي هي أو أية خدمات تطلب من خلال استخدام خدمات مدفوع،
      كما لا تضمن مدفوع عدم تعرض الخدمات للتعطيل أو خلوها من الخطأ. ولهذا فأنت تقر وتوافق على تحملك حصرا كامل المسؤولية
      الناشئة عن استخدامك لأي من الخدمات التي تقدمها مدفوع، وذلك إلى أقصى حد يسمح به النظام واجب التطبيق.</p>
    <p dir="RTL">10/6 لا تتحمل مدفوع المسؤولية عن أي تأخير أو إعاقة أو إخفاق في توفير الخدمات، إن كان السبب فيها يرجع
      إلى حدوث قوة قاهرة أو ظروف طارئة أو ما شابهه من أحداث تخرج عن نطاق تحكمنا أو سيطرتنا وكان من شأنها منع عملية تقديم
      الخدمة أو إعاقتها.</p>
    <p dir="RTL">10/7 أنت تقر وتوافق بموجب الاتفاقية على إبراء ذمة شركة مدفوع وأي من شركاتها التابعة ومسؤوليها ومديريها
      وموظفيها ووكلائها من وضد أية مطالبات، أو مسؤوليات، أو أضرار، أو خسائر، أو نفقات، أو تعويضها وإخلاء مسؤوليتها عن
      ذلك، بما في ذلك على سبيل المثال لا الحصر، الرسوم القانونية والمحاسبية الناشئة عن أو بأي طريقة تتعلق بما يلي:</p>
    <p dir="RTL">10/7/1 وصولك إلى حساب مدفوع أو خدمات الدفع من مدفوع أو استخدامها أو عدم القدرة على استخدامها.</p>
    <p dir="RTL">10/7/2 إهمالك أو سوء سلوكك.</p>
    <p dir="RTL">10/7/3 انتهاكك لأي نظام معمول به.</p>
    <p dir="RTL">10/7/4 إخفاقك في تقديم المعلومات الصحيحة والدقيقة والحديثة والكاملة فيما يتعلق بحساب مدفوع والاحتفاظ
      به.</p>
    <p dir="RTL">10/8 إذا لم توافق على أي من هذه الشروط والأحكام في الاتفاقية، يجب عليك الخروج من الموقع وعدم استخدام
      خدمات مدفوع؛ ولا يعفيك ذلك عن مسؤوليتك عن الالتزامات المتعلقة بخدمة مدفوع المقدمة لك سابقًا -إن وجدت-.</p>
    <p dir="RTL"><strong><u>&nbsp;(11): حقوق الملكية الفكرية:</u></strong></p>
    <p dir="RTL">11/1 يعتبر المحتوى الوارد على الموقع الإلكتروني أو التطبيق الخاص بمدفوع ملكية فكرية خاصة بمدفوع أو
      بمرخصيه، ويخضع هذا المحتوى للحماية بموجب حق المؤلف وحقوق العلامات التجارية وبراءات الاختراع وغير ذلك من حقوق
      الملكية الفكرية، ومن ذلك على سبيل المثال لا الحصر، التصاميم، والشعارات، والأيقونات، والصور، والمقاطع الصوتية
      والمرئية، والبرمجة، والشفرات الإلكترونية، وجميع البيانات الأخرى التي تحتويها المنصة.</p>
    <p dir="RTL">11/2&nbsp;يحظر على المستفيد أو أي زائر لمنصة مدفوع استخدام أي علامة تجارية أو أي حقوق ملكية فكرية تخص
      مدفوع، وذلك من أي جزء من الخدمات، كما يحظر عليه إعادة إنتاج الخدمات أو تعديلها أو إجراء أية عملية إعداد، أو نشر،
      أو تنفيذ، أو إرسال، أو عرض، أو بث، أو غير ذلك من وسائل استغلال الخدمات، ما لم تسمح مدفوع بذلك أو تأذن به صراحة
      بشكل كتابي. كما لا يجوز إجراء أعمال تفكيك لنظام الخدمات أو أي هندسة عكسية أو إعادة تشكيل لها، أو القيام بربط أي
      جزء من الخدمات بأي نظام عاكس، أو تأطيره، أو وضع أية برامج، أو نصوص لغرض إزالة أي جزء من الخدمات أو فهرستها أو
      مسحها أو إجراء غير ذلك من عمليات استخراج للبيانات منه، أو القيام بتحميل عملية التشغيل أو العمل الوظيفي لأي شكل من
      أشكال الخدمات بأحمال لا مبرر لها أو القيام بإعاقتها أو محاولة الحصول على إمكانية الدخول عليها دون تصريح بذلك أو
      العمل على الإضرار بالخدمات أو ما يرتبط بها من أنظمة أو شبكات.</p>
    <p dir="RTL"><strong><u>(12): التغيير أو التعديل على الاتفاقية:</u></strong></p>
    <p dir="RTL">12/1 يحق لمدفوع بعد إشعار المستفيد بمدة لا تقل عن ثلاثين (30) يومًا تعديل، أو تغيير، أو إضافة، أو حذف
      المواد من الاتفاقية في أي وقت دون أخذ موافقة من المستفيد، ويعد استمرار المستفيد في استخدام الخدمات محل الاتفاقية
      موافقة ضمنية منه على أي تغيير يطرأ على الاتفاقية.</p>
    <p dir="RTL">12/2 لا يحق للمستفيد التنازل عن أي حقوق أو التزامات منصوص عليها في الاتفاقية دون الحصول على موافقة خطية
      مسبقة من الشركة.</p>
    <p dir="RTL">12/3 يحق لمدفوع أن تتنازل عن الاتفاقية إلى طرف ثالث دون أخذ موافقة من المستفيد.</p>
    <p dir="RTL"><strong><u>(13): سريان وانتهاء الاتفاقية:</u></strong></p>
    <p dir="RTL">13/1 يبدأ العمل بأحكام الاتفاقية اعتباراً من وقت وصول المستفيد إلى منصة مدفوع عبر أي قناة من قنوات
      مدفوع، وتنطبق على أي إجراء يقوم به المستفيد خلال تواجده في المنصة.</p>
    <p dir="RTL">13/2 يجوز للمستفيد إنهاء الاتفاقية في أي وقت، من خلال التوقف عن استخدام منصة مدفوع بشكل كامل، ومع ذلك
      تبقى أي التزامات سابقة في ذمة المستفيد خاضعة للاتفاقية.</p>
    <p dir="RTL">13/3 يحق لمدفوع إنهاء الاتفاقية لأي سبب وفي أي وقت وذلك بعد إشعار المستفيد بمدة لا تقل عن ثلاثين (30)
      يومًا، ويقر ويوافق المستفيد على إنهاء الاتفاقية بسبب أو بدون سبب مع عدم التزماها بإبلاغه بسبب إنهاء الاتفاقية دون
      أدنى التزام على مدفوع تجاه المستفيد.</p>
    <p dir="RTL">13/4 يجوز للمستفيد طلب إغلاق حساب مدفوع الخاص به في أي وقت باتباع التعليمات الموجودة على الموقع
      الإلكتروني أو التطبيق، وتصبح جميع المبالغ المستحقة على المستفيد جراء استخدام خدمة مدفوع واجبة السداد عند إنهاء
      الاتفاقية.</p>
    <p dir="RTL">13/5 في حال عدم موافقة المستفيد على أي تعديلات تطرأ على الاتفاقية من قبل مدفوع، فيكون هذا الاتفاق ملغي
      خلال ثلاثين (30) يوماً من استلام الإشعار بعدم الموافقة، ويبقى المستفيد مسؤولًا عن جميع الالتزامات المتعلقة
      بحسابه.</p>
    <p dir="RTL"><strong><u>(14): عناوين المراسلات والإشعارات:</u></strong></p>
    <p dir="RTL">14/1 عند إنشاء حسابك، فإنك تقر وتوافق على أنك ستتواصل معنا إلكترونياً، وبالتالي توافق على استلام رسائل
      دورية من جانبنا وسوف يتواصل معك فريق مدفوع عبر البريد الإلكتروني أو قد يرسل لكم معلومات عبر رسائل نصية قصيرة،
      وكذلك القيام بنشر إخطارات على الموقع الإلكتروني كجزء من إدارة الأعمال المعتادة والتي ترتبط بعملية استخدامك
      للخدمات، وإنك تقر وتوافق بأن اختياركم عدم تطبيق وسائل التواصل المذكورة هو أمر من شأنه إحداث تأثير على عملية
      استخدامكم للخدمات.</p>
    <p dir="RTL">14/2 يقر المستفيد بعلمه أن أي إخطارات أو إعذارات من مدفوع إليه، تتم عبر العناوين ووسائل التواصل المقدمة
      من جانبه -بما في ذلك عنوان البريد الإلكتروني- وأنها هي العناوين المختارة لاستقبال كافة الإشعارات والمراسلات
      والتبليغات القضائية،&nbsp;</p>
    <p dir="RTL">14/3 يجب أن تكون جميع الإخطارات والمراسلات من المستفيد إلى مدفوع بموجب الاتفاقية كتابةً عبر المراسلات
      إلى البريد الإلكتروني للشركة الموضح على الموقع الإلكتروني.</p>
    <p dir="RTL"><span dir="LTR">&nbsp;14/4 يجب على المستفيد إخطار مدفوع فورًا وبحد أقصى خلال خمسة (5) أيام تقويمية بأي تغيرات تطرأ على عنوانه المعتمد، ووسائل التواصل كرقم الجوال أو البريد الإلكتروني أو أي معلومات أخرى، وفي حال إخلاله بهذا الالتزام تعتبر جميع الإشعارات والمراسلات والتبليغات القضائية التي ترسل إلى عناوينه المشار إليها مستلمة وسارية المفعول نظامًا وقضاءً؛ ويقر بتحمله كامل المسؤولية عن الأضرار المترتبة على إخلاله بهذا الالتزام.&nbsp;</span>
    </p>
    <p dir="RTL"><strong><u>(15): القانون المنطبق:</u></strong></p>
    <p dir="RTL">تخضع هذه الشروط والأحكام والسياسات الواردة في الاتفاقية وتفسر وفقاً لأنظمة المملكة العربية
      السعودية.</p>
    <p dir="RTL"><strong><u>(16): حل النزاعات:</u></strong></p>
    <p dir="RTL">تتم تسوية أي نزاع أو خلاف أو مطالبة تنشأ عن أو تتعلق بالاتفاقية، أو مخالفتها، أو إنهائها، أو بطلانها،
      عن طريق المحكمة أو اللجنة القضائية المختصة في مدينة الرياض، المملكة العربية السعودية.</p>
    <p dir="RTL"><strong><u>(17): الاتفاقية الكاملة</u></strong><span dir="LTR">:</span></p>
    <p dir="RTL">تشكل الاتفاقية، إلى جانب أي تعديلات وأي اتفاقيات إضافية تتعلق باستخدام المنصة والخدمات، الاتفاقية
      الكاملة بينك وبين مدفوع، وقبل إتمام أي معاملة باستخدام خدمات مدفوع، يجب عليك الموافقة على هذه الاتفاقية، ويعني
      النقر فوق الزر "<strong>إتمام العملية</strong>" إلى أنك قرأت الاتفاقية وفهمتها وقبلتها بالكامل وأنك ملتزم بدفع
      المبلغ الإجمالي المستحق لمدفوع</p>
    <p dir="RTL"><strong><u>(18): معلومات التواصل:</u></strong></p>
    <p dir="RTL">إذا كان لديك أي أسئلة بخصوص الاتفاقية أو منصة مدفوع، يرجى منك زيارة صفحة "المساعدة" للحصول على معلومات
      الاتصال الخاصة بنا.</p>
  </div>
</div>
