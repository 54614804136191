import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationLanguages } from 'src/app/enums/lang';

@Component({
  selector: 'app-email-confirmation-or-expired',
  templateUrl: './email-confirmation-or-expired.component.html',
  styleUrls: ['./email-confirmation-or-expired.component.scss'],
})
export class EmailConfirmationOrExpiredComponent implements OnInit {
  success = false;
  ApplicationLanguages = ApplicationLanguages;
  language = ApplicationLanguages.Ar;

  title = 'email-confirmation-failure-title';
  message = 'email-confirmation-failure-message';
  image = '/assets/images/expired-time.png';

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams['success'] === 'true') {
      this.success = true;
    }

    this.setData();
  }

  setData() {
    this.setContent();
    this.setImage();
  }

  setContent() {
    if (this.success) {
      this.title = 'email-confirmation-success-title';
      this.message = 'email-confirmation-success-message';
    } else {
      this.title = 'email-confirmation-failure-title';
      this.message = 'email-confirmation-failure-message';
    }
  }

  setImage() {
    if (this.success) {
      this.image = '/assets/images/confirmation.png';
    } else {
      this.image = '/assets/images/expired-time.png';
    }
  }
}
